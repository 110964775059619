const setInitalClusterIdData = (state, payload = {}) => {
  const {cluster_ids} = payload;
  if (cluster_ids) {
    const oldClusters = state;
    const newClusters = cluster_ids;
    return [...oldClusters, ...newClusters];
  } else return state;
};

export default (state = [], action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default: {
      return setInitalClusterIdData(state, payload);
    }
  }
};
