export default (state = [], action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default: {
      if (payload.consignor_ids) {
        return payload.consignor_ids;
      }
      return state;
    }
  }
};
