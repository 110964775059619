const setInitalCustomerIdData = (state, payload = {}) => {
  const {customer_ids} = payload;
  if (customer_ids) {
    const oldCustomers = state;
    const newCustomers = customer_ids;

    return [...new Set([...oldCustomers, ...newCustomers])];
  } else return state;
};

export default (state = [], action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default: {
      return setInitalCustomerIdData(state, payload);
    }
  }
};
