import {merge} from 'lodash';
import {EDIT_CONSIGNOR_COMPLETED} from '../record';

const setUpdatedConsignors = (state, payload = {}) => {
  const {consignors} = payload;
  if (consignors) {
    const oldConsignors = {...state};
    const newConsignors = {...consignors};
    const updatedState = merge(oldConsignors, newConsignors);
    return {...updatedState};
  } else return state;
};

export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    case EDIT_CONSIGNOR_COMPLETED:
      return setUpdatedConsignors(state, payload);
    default:
      return setUpdatedConsignors(state, payload);
  }
};
