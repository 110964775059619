export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;

  switch (type) {
    default: {
      if (payload?.is_transin_user?.toString()) {
        return {
          ...state,
          user: payload?.is_transin_user,
        };
      }
      return state;
    }
  }
};
