// export default (state = {}, action = {}) => {
//     const {type, payload = {}} = action;
//     switch (type) {
//       default: {
//         if (payload.engaged_by_ids) {
//           return {...state, ...payload.engaged_by_ids};
//         }
//         return state;
//       }
//     }
//   };

const setInitalVehicleEngagedByIdData = (state, payload = {}) => {
  const {engaged_by_ids} = payload;
  if (engaged_by_ids) {
    const oldVehicleEngagedBy = state;
    const newVehicleEngagedBy = engaged_by_ids;

    return [...new Set([...oldVehicleEngagedBy, ...newVehicleEngagedBy])];
  } else return state;
};

export default (state = [], action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default: {
      return setInitalVehicleEngagedByIdData(state, payload);
    }
  }
};
