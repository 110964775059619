import {doRequest} from '../../helpers/network';
import {Cost} from '../../helpers/Urls';
import {REQUEST_TYPE} from '../../constants';

export const SEARCH_COST_ANNEXURES_DETAILS = 'SEARCH_COST_ANNEXURES_DETAILS';
export const SEARCH_COST_ANNEXURES_DETAILS_COMPLETED =
  'SEARCH_COST_ANNEXURES_DETAILS_COMPLETED';
export const SEARCH_COST_ANNEXURES_DETAILS_FAILED =
  'SEARCH_COST_ANNEXURES_DETAILS_FAILED';

export const DELETE_COST_ANNEXURES = 'DELETE_COST_ANNEXURES';
export const DELETE_COST_ANNEXURES_COMPLETED =
  'DELETE_COST_ANNEXURES_COMPLETED';
export const DELETE_COST_ANNEXURES_FAILED = 'DELETE_COST_ANNEXURES_FAILED';

export const COST_OTHERS_HEADER_EDIT = 'COST_OTHERS_HEADER_EDIT';
export const COST_OTHERS_HEADER_EDIT_COMPLETED =
  'COST_OTHERS_HEADER_EDIT_COMPLETED';
export const COST_OTHERS_HEADER_EDIT_FAILED = 'COST_OTHERS_HEADER_EDIT_FAILED';

export const DELETE_COST_TRIPS_ANNEXURE = 'DELETE_COST_TRIPS_ANNEXURE';
export const DELETE_COST_TRIPS_ANNEXURE_COMPLETED =
  'DELETE_COST_TRIPS_ANNEXURE_COMPLETED';
export const DELETE_COST_TRIPS_ANNEXURE_FAILED =
  'DELETE_COST_TRIPS_ANNEXURE_FAILED';

export const PRINT_COST_ANNEXURE = 'PRINT_COST_ANNEXURE';
export const PRINT_COST_ANNEXURE_COMPLETED = 'PRINT_COST_ANNEXURE_COMPLETED';
export const PRINT_COST_ANNEXURE_FAILED = 'PRINT_COST_ANNEXURE_FAILED';

export const SEND_FOR_APPROVAL_COST = 'SEND_FOR_APPROVAL_COST';
export const SEND_FOR_APPROVAL_COST_COMPLETED =
  'SEND_FOR_APPROVAL_COST_COMPLETED';
export const SEND_FOR_APPROVAL_COST_FAILED = 'SEND_FOR_APPROVAL_COST_FAILED';

export const SAVE_COST_BILL_DETAILS = 'SAVE_COST_BILL_DETAILS';
export const SAVE_COST_BILL_DETAILS_COMPLETED =
  'SAVE_COST_BILL_DETAILS_COMPLETED';
export const SAVE_COST_BILL_DETAILS_FAILED = 'SAVE_COST_BILL_DETAILS_FAILED';

export const APPROVE_COST_ANNEXURE = 'APPROVE_COST_ANNEXURE';
export const APPROVE_COST_ANNEXURE_COMPLETED =
  'APPROVE_COST_ANNEXURE_COMPLETED';
export const APPROVE_COST_ANNEXURE_FAILED = 'APPROVE_COST_ANNEXURE_FAILED';

export const REJECT_COST_ANNEXURE = 'REJECT_COST_ANNEXURE';
export const REJECT_COST_ANNEXURE_COMPLETED = 'REJECT_COST_ANNEXURE_COMPLETED';
export const REJECT_COST_ANNEXURE_FAILED = 'REJECT_COST_ANNEXURE_FAILED';

export const COST_ANNEXURE_UPLOAD_BILL = 'COST_ANNEXURE_UPLOAD_BILL';
export const COST_ANNEXURE_UPLOAD_BILL_COMPLETED =
  'COST_ANNEXURE_UPLOAD_BILL_COMPLETED';
export const COST_ANNEXURE_UPLOAD_BILL_FAILED =
  'COST_ANNEXURE_UPLOAD_BILL_FAILED';

export const CREATE_COST_NEXT_ANNEXURE = 'CREATE_COST_NEXT_ANNEXURE';
export const CREATE_COST_NEXT_ANNEXURE_COMPLETED =
  'CREATE_COST_NEXT_ANNEXURE_COMPLETED';
export const CREATE_COST_NEXT_ANNEXURE_FAILED =
  'CREATE_COST_NEXT_ANNEXURE_FAILED';

export const CREATE_DISPUTE_COST_ANNEXURE = 'CREATE_DISPUTE_COST_ANNEXURE';
export const CREATE_DISPUTE_COST_ANNEXURE_COMPLETED =
  'CREATE_DISPUTE_COST_ANNEXURE_COMPLETED';
export const CREATE_DISPUTE_COST_ANNEXURE_FAILED =
  'CREATE_DISPUTE_COST_ANNEXURE_FAILED';

export const GET_PARTNER_DISPUTE = 'GET_PARTNER_DISPUTE';
export const GET_PARTNER_DISPUTE_COMPLETED = 'GET_PARTNER_DISPUTE_COMPLETED';
export const GET_PARTNER_DISPUTE_FAILED = 'GET_PARTNER_DISPUTE_FAILED';

export const SEARCH_OWN_BILLING_ANNEXURES_DATA =
  'SEARCH_OWN_BILLING_ANNEXURES_DATA';
export const SEARCH_OWN_BILLING_ANNEXURES_DATA_COMPLETED =
  'SEARCH_OWN_BILLING_ANNEXURES_DATA_COMPLETED';
export const SEARCH_OWN_BILLING_ANNEXURES_DATA_FAILED =
  'SEARCH_OWN_BILLING_ANNEXURES_DATA_FAILED';

export const VIEW_PRINT_OWN_BILLING_ANNEXURE =
  'VIEW_PRINT_OWN_BILLING_ANNEXURE';
export const VIEW_PRINT_OWN_BILLING_ANNEXURE_COMPLETED =
  'VIEW_PRINT_OWN_BILLING_ANNEXURE_COMPLETED';
export const VIEW_PRINT_OWN_BILLING_ANNEXURE_FAILED =
  'VIEW_PRINT_OWN_BILLING_ANNEXURE_FAILED';

const CLEAR_SEARCH_COST_ANNEXURES_DATA = 'CLEAR_SEARCH_COST_ANNEXURES_DATA';
const RESET_PAGE = 'RESET_PAGE';

const initialState = {
  isFetching: false,
  error: false,
  hasError: false,
  resetPage: false,
  costAnnexuresData: {},
  annexureDisputeInfo: {},
  isRequestingPartnerDispute: false,
  savingCostBillDetails: false,
  uploadingCostBill: false,
  deletingCostAnnex: false,
  requestingCostNextAnnex: false,
  fetchingCostAnnexure: false,
  requestingDisputeCostAnnex: false,
  fetchOwnBillingAnnexureData: false,
  ownBillingAnnexureData: {},
  ownBillingAnnexureIDs: [],
  viewDataLoading: false,
  totalData: null,
};

const updateAnnexures = (annexureIds, payloadAnnexures) => {
  return annexureIds.reduce((acc, curr) => {
    acc[curr] = {
      basicInfo: {
        ...payloadAnnexures[curr].basicInfo,
      },
    };
    return acc;
  }, {});
};

export const searchCostAnnexuresData = (
  requestData = {},
  page,
  resetPage = true,
) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SEARCH_COST_ANNEXURES_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Cost.searchCostAnnexuresDataURL(page),
        data: requestData,
      });
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }
      if (status) {
        dispatch({
          type: SEARCH_COST_ANNEXURES_DETAILS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: SEARCH_COST_ANNEXURES_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_COST_ANNEXURES_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const clearSearchCostAnnexuresData = () => (dispatch) => {
  dispatch({
    type: CLEAR_SEARCH_COST_ANNEXURES_DATA,
  });
};

export const getPartnerDispute = (annexureId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: GET_PARTNER_DISPUTE});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Cost.getPartnerDisputeURL(annexureId),
      });
      console.log('response --GET_PARTNER_DISPUTE--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: GET_PARTNER_DISPUTE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: GET_PARTNER_DISPUTE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: GET_PARTNER_DISPUTE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const createDisputeCostAnnexure = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: CREATE_DISPUTE_COST_ANNEXURE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Cost.createDisputeCostAnnexureURL(),
        data: requestData,
      });
      console.log('response --CREATE_DISPUTE_COST_ANNEXURE--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: CREATE_DISPUTE_COST_ANNEXURE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: CREATE_DISPUTE_COST_ANNEXURE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CREATE_DISPUTE_COST_ANNEXURE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const createCostNextAnnexure = (annexureId, requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: CREATE_COST_NEXT_ANNEXURE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Cost.createCostNextAnnexureURL(annexureId),
        data: requestData,
      });
      console.log('response --CREATE_COST_NEXT_ANNEXURE--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: CREATE_COST_NEXT_ANNEXURE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: CREATE_COST_NEXT_ANNEXURE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CREATE_COST_NEXT_ANNEXURE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const saveCostBillDetails = (annexureId, requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SAVE_COST_BILL_DETAILS});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Cost.saveCostBillDetailsURL(annexureId),
        data: requestData,
      });
      console.log('response --SAVE_COST_BILL_DETAILS--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: SAVE_COST_BILL_DETAILS_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: SAVE_COST_BILL_DETAILS_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SAVE_COST_BILL_DETAILS_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const sendForApprovalCost = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SEND_FOR_APPROVAL_COST});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Cost.sendForApprovalCostURL(),
        data: requestData,
      });
      console.log('response --SEND_FOR_APPROVAL_COST--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: SEND_FOR_APPROVAL_COST_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: SEND_FOR_APPROVAL_COST_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SEND_FOR_APPROVAL_COST_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const printCostAnnexure = (annexureId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: PRINT_COST_ANNEXURE});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Cost.printCostAnnexureURL(annexureId),
      });
      console.log('response --PRINT_COST_ANNEXURE--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: PRINT_COST_ANNEXURE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: PRINT_COST_ANNEXURE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: PRINT_COST_ANNEXURE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const deleteCostAnnexures = (requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DELETE_COST_ANNEXURES});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Cost.deleteCostAnnexuresURL(),
        data: requestData,
      });
      console.log('response --DELETE_COST_ANNEXURES--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DELETE_COST_ANNEXURES_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: DELETE_COST_ANNEXURES_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DELETE_COST_ANNEXURES_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const costOthersHeaderEdit = (annexureId, requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: COST_OTHERS_HEADER_EDIT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Cost.costOthersHeaderEditURL(annexureId),
        data: requestData,
      });
      console.log('response --COST_OTHERS_HEADER_EDIT--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: COST_OTHERS_HEADER_EDIT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: COST_OTHERS_HEADER_EDIT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: COST_OTHERS_HEADER_EDIT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const approveCostAnnexure = (annexureId, requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: APPROVE_COST_ANNEXURE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Cost.approveCostAnnexureURL(annexureId),
        data: requestData,
      });
      console.log('response --APPROVE_COST_ANNEXURE--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: APPROVE_COST_ANNEXURE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: APPROVE_COST_ANNEXURE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: APPROVE_COST_ANNEXURE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const rejectCostAnnexure = (annexureId, requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: REJECT_COST_ANNEXURE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Cost.rejectCostAnnexureURL(annexureId),
        data: requestData,
      });
      console.log('response --REJECT_COST_ANNEXURE--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: REJECT_COST_ANNEXURE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: REJECT_COST_ANNEXURE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: REJECT_COST_ANNEXURE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const costAnnexureUploadBill = (annexureId, requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: COST_ANNEXURE_UPLOAD_BILL});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Cost.costAnnexureUploadBillURL(annexureId),
        data: requestData,
      });
      console.log('response --COST_ANNEXURE_UPLOAD_BILL--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: COST_ANNEXURE_UPLOAD_BILL_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: COST_ANNEXURE_UPLOAD_BILL_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: COST_ANNEXURE_UPLOAD_BILL_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const deleteCostTripsAnnexure = (annexureId, requestData = {}) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: DELETE_COST_TRIPS_ANNEXURE});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Cost.deleteCostTripsAnnexureURL(annexureId),
        data: requestData,
      });
      console.log('response --DELETE_COST_TRIPS_ANNEXURE--', response);
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: DELETE_COST_TRIPS_ANNEXURE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: DELETE_COST_TRIPS_ANNEXURE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: DELETE_COST_TRIPS_ANNEXURE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const searchOwnBillingAnnexure = (
  requestData = {},
  page,
  resetPage = true,
) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: SEARCH_OWN_BILLING_ANNEXURES_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: Cost.searchOwnBillingAnnexuresDataURL(page),
        data: requestData,
      });
      const {data, status} = response || {};
      if (resetPage) {
        dispatch({type: RESET_PAGE});
      }
      if (status) {
        dispatch({
          type: SEARCH_OWN_BILLING_ANNEXURES_DATA_COMPLETED,
          payload: data,
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: SEARCH_OWN_BILLING_ANNEXURES_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: SEARCH_OWN_BILLING_ANNEXURES_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export const viewOrPrintOwnBillingAnnexure = (annexureId) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: VIEW_PRINT_OWN_BILLING_ANNEXURE});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Cost.viewOrPrintOwnBillingAnnexureURL(annexureId),
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({
          type: VIEW_PRINT_OWN_BILLING_ANNEXURE_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: VIEW_PRINT_OWN_BILLING_ANNEXURE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: VIEW_PRINT_OWN_BILLING_ANNEXURE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;
  let updateAnnx;
  switch (type) {
    case SEARCH_OWN_BILLING_ANNEXURES_DATA:
      return {
        ...state,
        fetchOwnBillingAnnexureData: true,
      };
    case SEARCH_OWN_BILLING_ANNEXURES_DATA_COMPLETED:
      return {
        ...state,
        fetchOwnBillingAnnexureData: false,
        hasError: false,
        error: '',
        ownBillingAnnexureData: payload?.annexures,
        ownBillingAnnexureIDs: payload?.annexure_ids,
        totalData: payload?.total,
      };
    case SEARCH_OWN_BILLING_ANNEXURES_DATA_FAILED:
      return {
        ...state,
        fetchOwnBillingAnnexureData: false,
        error: payload.error,
        hasError: true,
      };
    case VIEW_PRINT_OWN_BILLING_ANNEXURE:
      return {
        ...state,
        viewDataLoading: false,
      };
    case VIEW_PRINT_OWN_BILLING_ANNEXURE_COMPLETED:
      return {
        ...state,
        viewDataLoading: true,
      };
    case VIEW_PRINT_OWN_BILLING_ANNEXURE_FAILED:
      return {
        ...state,
        viewDataLoading: false,
      };
    case SEARCH_COST_ANNEXURES_DETAILS:
      return {
        ...state,
        isFetching: true,
      };
    case SEARCH_COST_ANNEXURES_DETAILS_FAILED:
      return {
        ...state,
        isFetching: false,
        error: payload.error,
        hasError: true,
      };
    case SEARCH_COST_ANNEXURES_DETAILS_COMPLETED:
      return {
        ...state,
        isFetching: false,
        error: '',
        hasError: false,
        costAnnexuresData: {...payload},
      };
    case CLEAR_SEARCH_COST_ANNEXURES_DATA:
      return {
        ...state,
        costAnnexuresData: {},
      };
    case SEND_FOR_APPROVAL_COST:
      return {
        ...state,
        sendingForCostAnnexApproval: true,
      };
    case SEND_FOR_APPROVAL_COST_COMPLETED:
      updateAnnx = updateAnnexures(payload.annexure_ids, payload.annexures);

      let costAnnexuresData = {
        ...state['costAnnexuresData'],
        annexures: {
          ...state['costAnnexuresData'].annexures,
          ...updateAnnx,
        },
      };

      return {
        ...state,
        sendingForCostAnnexApproval: false,
        costAnnexuresData,
      };
    case SEND_FOR_APPROVAL_COST_FAILED:
      return {
        ...state,
        sendingForCostAnnexApproval: false,
      };
    // approve/reject annexure for cost starts
    case APPROVE_COST_ANNEXURE:
      return {
        ...state,
        approvingCostAnnex: true,
      };
    case APPROVE_COST_ANNEXURE_COMPLETED:
      return {
        ...state,
        approvingCostAnnex: false,
      };
    case APPROVE_COST_ANNEXURE_FAILED:
      return {
        ...state,
        approvingCostAnnex: false,
      };
    case REJECT_COST_ANNEXURE:
      return {
        ...state,
        rejectingCostAnnex: true,
      };
    case REJECT_COST_ANNEXURE_COMPLETED:
      return {
        ...state,
        rejectingCostAnnex: false,
      };
    case REJECT_COST_ANNEXURE_FAILED:
      return {
        ...state,
        rejectingCostAnnex: false,
      };
    // approve/reject annexure for cost ends
    case SAVE_COST_BILL_DETAILS:
      return {
        ...state,
        savingCostBillDetails: true,
      };
    case SAVE_COST_BILL_DETAILS_COMPLETED:
      updateAnnx = updateAnnexures(payload.annexure_ids, payload.annexures);

      let annexuresBillNumberChange = {
        ...state['costAnnexuresData'],
        annexures: {
          ...state['costAnnexuresData'].annexures,
          ...updateAnnx,
        },
      };

      return {
        ...state,
        savingCostBillDetails: false,
        costAnnexuresData: annexuresBillNumberChange,
      };
    // return {
    //   ...state,
    //   //   ...payload.trips,
    //   savingCostBillDetails: false,
    // };
    case SAVE_COST_BILL_DETAILS_FAILED:
      return {
        ...state,
        savingCostBillDetails: false,
      };
    case COST_ANNEXURE_UPLOAD_BILL:
      return {
        ...state,
        uploadingCostBill: true,
      };
    case COST_ANNEXURE_UPLOAD_BILL_COMPLETED:
      return {
        ...state,
        uploadingCostBill: false,
      };
    case COST_ANNEXURE_UPLOAD_BILL_FAILED:
      return {
        ...state,
        uploadingCostBill: false,
      };
    case DELETE_COST_ANNEXURES:
      return {
        ...state,
        deletingCostAnnex: true,
      };
    case DELETE_COST_ANNEXURES_COMPLETED:
      updateAnnx = updateAnnexures(payload.annexure_ids, payload.annexures);

      let annexuresDataDelete = {
        ...state['costAnnexuresData'],
        annexures: {
          ...state['costAnnexuresData'].annexures,
          ...updateAnnx,
        },
      };

      return {
        ...state,
        deletingCostAnnex: false,
        costAnnexuresData: annexuresDataDelete,
      };
    // return {
    //   ...state,
    //   deletingCostAnnex: false,
    // };
    case DELETE_COST_ANNEXURES_FAILED:
      return {
        ...state,
        deletingCostAnnex: false,
      };
    case CREATE_COST_NEXT_ANNEXURE:
      return {
        ...state,
        requestingCostNextAnnex: true,
      };
    case CREATE_COST_NEXT_ANNEXURE_COMPLETED:
      return {
        ...state,
        //   ...payload.trips,
        requestingCostNextAnnex: false,
      };
    case CREATE_COST_NEXT_ANNEXURE_FAILED:
      return {
        ...state,
        requestingCostNextAnnex: false,
      };
    case CREATE_DISPUTE_COST_ANNEXURE:
      return {
        ...state,
        requestingDisputeCostAnnex: true,
      };
    case CREATE_DISPUTE_COST_ANNEXURE_COMPLETED:
      return {
        ...state,
        //   ...payload.trips,
        requestingDisputeCostAnnex: false,
      };
    case CREATE_DISPUTE_COST_ANNEXURE_FAILED:
      return {
        ...state,
        requestingDisputeCostAnnex: false,
      };
    case COST_OTHERS_HEADER_EDIT:
      return {
        ...state,
        updatingCostOthers: true,
      };
    case COST_OTHERS_HEADER_EDIT_COMPLETED:
      return {
        ...state,
        updatingCostOthers: false,
      };
    case COST_OTHERS_HEADER_EDIT_FAILED:
      return {
        ...state,
        updatingCostOthers: false,
      };
    case GET_PARTNER_DISPUTE:
      return {
        ...state,
        isRequestingPartnerDispute: true,
      };
    case GET_PARTNER_DISPUTE_COMPLETED:
      return {
        ...state,
        isRequestingPartnerDispute: false,
        annexureDisputeInfo: payload,
      };
    case GET_PARTNER_DISPUTE_FAILED:
      return {
        ...state,
        isRequestingPartnerDispute: false,
      };
    case PRINT_COST_ANNEXURE:
      return {
        ...state,
        printingCostAnnex: true,
      };
    case PRINT_COST_ANNEXURE_COMPLETED:
      return {
        ...state,
        printingCostAnnex: false,
      };
    case PRINT_COST_ANNEXURE_FAILED:
      return {
        ...state,
        printingCostAnnex: false,
      };
    default: {
      if (payload.trips) {
        console.log('Payload', payload);
        return {...state, ...payload.trips};
      }
      return state;
    }
  }
};
