export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default: {
      if (payload.engaged_by) {
        return {...state, ...payload.engaged_by};
      }
      return state;
    }
  }
};
