export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;

  switch (type) {
    default: {
      if (payload.booking_status) {
        return {...state, ...payload.booking_status};
      }
      return state;
    }
  }
};
