import {doRequest} from '../../helpers/network';
import {REQUEST_TYPE} from '../../constants';
import {Records} from '../../helpers/Urls';
import {merge} from 'lodash';

const FETCH_CUSTOMER_BY_ID = 'FETCH_CUSTOMER_BY_ID';
const FETCH_CUSTOMER_BY_ID_COMPLETED = 'FETCH_CUSTOMER_BY_ID_COMPLETED';
const FETCH_CUSTOMER_BY_ID_FAILED = 'FETCH_CUSTOMER_BY_ID_FAILED';

const FETCH_CUSTOMER_INITIAL_DATA = 'FETCH_CUSTOMER_INITIAL_DATA';
const FETCH_CUSTOMER_INITIAL_DATA_COMPLETED =
  'FETCH_CUSTOMER_INITIAL_DATA_COMPLETED';
const FETCH_CUSTOMER_INITIAL_DATA_FAILED = 'FETCH_CUSTOMER_INITIAL_DATA_FAILED';

const FETCH_CUSTOMER_INITIAL_DATA_CSC_STATE =
  'FETCH_CUSTOMER_INITIAL_DATA_CSC_STATE';
const FETCH_CUSTOMER_INITIAL_DATA_CSC_STATE_COMPLETED =
  'FETCH_CUSTOMER_INITIAL_DATA_CSC_STATE_COMPLETED';
const FETCH_CUSTOMER_INITIAL_DATA_CSC_STATE_FAILED =
  'FETCH_CUSTOMER_INITIAL_DATA_CSC_STATE_FAILED';

const FETCH_CUSTOMER_INITIAL_DATA_CSC_CITY =
  'FETCH_CUSTOMER_INITIAL_DATA_CSC_CITY';
const FETCH_CUSTOMER_INITIAL_DATA_CSC_CITY_COMPLETED =
  'FETCH_CUSTOMER_INITIAL_DATA_CSC_CITY_COMPLETED';
const FETCH_CUSTOMER_INITIAL_DATA_CSC_CITY_FAILED =
  'FETCH_CUSTOMER_INITIAL_DATA_CSC_CITY_FAILED';

export const fetchCustomerById = (customer_id = null) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_CUSTOMER_BY_ID});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Records.editCustomerURL(customer_id),
      });
      console.log('response --FETCH_CUSTOMER_BY_ID--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: FETCH_CUSTOMER_BY_ID_COMPLETED,
          payload: data,
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_CUSTOMER_BY_ID_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_CUSTOMER_BY_ID_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const fetchCustomerInitialData = () => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_CUSTOMER_INITIAL_DATA});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: Records.fetchCustomerInitialDataURL(),
      });
      console.log('response --FETCH_CUSTOMER_BY_ID--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: FETCH_CUSTOMER_INITIAL_DATA_COMPLETED,
          payload: data,
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_CUSTOMER_INITIAL_DATA_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_CUSTOMER_INITIAL_DATA_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const fetchCustomerInitialDataCSCState = (filter) => {
  const {flag} = filter;
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_CUSTOMER_INITIAL_DATA_CSC_STATE});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url:
          flag === 'Country'
            ? Records.fetchCustomerInitialDataCSCCountryURL(filter)
            : Records.fetchCustomerInitialDataCSCStateURL(filter),
      });
      console.log('response --FETCH_CUSTOMER_BY_ID--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: FETCH_CUSTOMER_INITIAL_DATA_CSC_STATE_COMPLETED,
          payload: data,
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_CUSTOMER_INITIAL_DATA_CSC_STATE_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_CUSTOMER_INITIAL_DATA_CSC_STATE_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};
export const fetchCustomerInitialDataCSCCity = (filter) => {
  const {flag} = filter;
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: FETCH_CUSTOMER_INITIAL_DATA_CSC_CITY});
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url:
          flag === 'Country'
            ? Records.fetchCustomerInitialDataCSCCountryURL(filter)
            : Records.fetchCustomerInitialDataCSCStateURL(filter),
      });
      console.log('response --FETCH_CUSTOMER_BY_ID--', response);
      const {data, status} = response || {};

      if (status === true) {
        dispatch({
          type: FETCH_CUSTOMER_INITIAL_DATA_CSC_CITY_COMPLETED,
          payload: data,
        });
      } else if (status === false) {
        const {message = 'Error'} = response || {};
        dispatch({
          type: FETCH_CUSTOMER_INITIAL_DATA_CSC_CITY_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_CUSTOMER_INITIAL_DATA_CSC_CITY_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

const setInitialCustomersData = (state, payload = {}) => {
  const {customers} = payload;
  if (customers) {
    const oldCustomers = {...state};
    const newCustomers = {...customers};
    const updatedState = merge(oldCustomers, newCustomers);
    return {...updatedState};
  } else return state;
};

export default (
  state = {
    customerInitialDataLoading: false,
    customerInitialData: {},
    customerInitialDataCSCStateLoading: false,
    customerInitialDataCSCState: {},
    customerInitialDataCSCCityLoading: false,
    customerInitialDataCSCCity: {},
  },
  action = {},
) => {
  const {type, payload = {}} = action;
  switch (type) {
    case FETCH_CUSTOMER_INITIAL_DATA:
      return {
        ...state,
        customerInitialDataLoading: true,
      };
    case FETCH_CUSTOMER_INITIAL_DATA_COMPLETED:
      return {
        ...state,
        customerInitialDataLoading: false,
        customerInitialData: payload,
      };
    case FETCH_CUSTOMER_INITIAL_DATA_FAILED:
      return {
        ...state,
        customerInitialDataCSCLoading: false,
      };
    case FETCH_CUSTOMER_INITIAL_DATA_CSC_STATE:
      return {
        ...state,
        customerInitialDataCSCStateLoading: true,
      };
    case FETCH_CUSTOMER_INITIAL_DATA_CSC_STATE_COMPLETED:
      return {
        ...state,
        customerInitialDataCSCStateLoading: false,
        customerInitialDataCSCState: payload,
      };
    case FETCH_CUSTOMER_INITIAL_DATA_CSC_STATE_FAILED:
      return {
        ...state,
        customerInitialDataCSCStateLoading: false,
      };
    case FETCH_CUSTOMER_INITIAL_DATA_CSC_CITY:
      return {
        ...state,
        customerInitialDataCSCCityLoading: true,
      };
    case FETCH_CUSTOMER_INITIAL_DATA_CSC_CITY_COMPLETED:
      return {
        ...state,
        customerInitialDataCSCCityLoading: false,
        customerInitialDataCSCCity: payload,
      };
    case FETCH_CUSTOMER_INITIAL_DATA_CSC_CITY_FAILED:
      return {
        ...state,
        customerInitialDataCSCCityLoading: false,
      };
    default: {
      return setInitialCustomersData(state, payload);
    }
  }
};
