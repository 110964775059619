const setInitalKamIdData = (state, payload = {}) => {
  const {kam_ids} = payload;
  if (kam_ids) {
    const oldKams = state;
    const newKams = kam_ids;
    return [...oldKams, ...newKams];
  } else return state;
};

export default (state = [], action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default: {
      return setInitalKamIdData(state, payload);
    }
  }
};
