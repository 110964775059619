import {REQUEST_TYPE} from '../../constants';
import {doRequest} from '../../helpers/network';

const INITIAL_STATE = {
  disputeLoading: false,
  disputeData: [],
};

// First Page
export const getDisputeData = () => {
  return async (dispatch) => {
    let response = {};

    dispatch({type: 'DISPUTE_START_FETCHING_DATA'});
    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: 'finance/partner-dispute/',
      });
      const {data, status} = response || {};
      if (status) {
        const {partner_dispute_ids, partner_disputes} = data;
        dispatch({type: 'DISPUTE_TABLE_DATA', payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'DISPUTE_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'DISPUTE_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

// When we click reslove dispute
export const resolveDispute = (id) => {
  return async (dispatch) => {
    let response = {};

    dispatch({type: 'DISPUTE_START_FETCHING_DATA'});
    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: `finance/partner-dispute/${id}/`,
      });
      const {data, status} = response || {};
      if (status) {
        const {partner_dispute_ids, partner_disputes, trip_ids, trips} = data;
        dispatch({
          type: 'RESOLVE_DISPUTE_TABLE_DATA',
          payload: {
            partner_dispute_ids,
            partner_disputes,
            trip_ids,
            trips,
          },
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'DISPUTE_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'DISPUTE_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

// Edit Approved_Amount and Remarks
export const approvedAmountRemarks = (myData, id) => {
  return async (dispatch) => {
    let response = {};

    dispatch({type: 'DISPUTE_START_FETCHING_DATA'});
    try {
      response = await doRequest({
        method: REQUEST_TYPE.PATCH,
        url: `finance/partner-dispute/${id}/`,
        data: myData,
      });
      const {data, status} = response || {};
      if (status) {
        console.log('response', data);
        dispatch({
          type: 'EDIT_APPROVED_AMOUNT_REMARKS',
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'DISPUTE_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'DISPUTE_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

// Send For Approval
export const sendForApproval = (myData) => {
  return async (dispatch) => {
    let response = {};

    dispatch({type: 'DISPUTE_APPROVAL'});
    try {
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: `finance/partner-dispute/send_for_approval/`,
        data: myData,
      });
      const {data, status} = response || {};
      if (status) {
        console.log('response', data);
        dispatch({
          type: 'SEND_FOR_APPROVAL',
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'DISPUTE_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'DISPUTE_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

// Final Approve
export const finalApprove = (id) => {
  return async (dispatch) => {
    let response = {};

    dispatch({type: 'FINAL_APPROVE_FETCH'});
    try {
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: `finance/partner-dispute/${id}/approve/`,
      });
      const {data, status} = response || {};
      if (status) {
        console.log('response', data);
        dispatch({
          type: 'FINAL_DONE_APPROVE_FETCH',
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'DISPUTE_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'DISPUTE_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

// Final Reject
export const finalReject = (sendData, id) => {
  return async (dispatch) => {
    let response = {};

    dispatch({type: 'FINAL_REJECT_FETCH'});
    try {
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: `/finance/partner-dispute/${id}/reject/`,
        data: sendData,
      });
      const {data, status} = response || {};
      if (status) {
        console.log('response', data);
        dispatch({
          type: 'FINAL_DONE_REJECT_FETCH',
          payload: data,
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'DISPUTE_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'DISPUTE_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

// SEARCH CALL
export const getSearchDispute = (search) => {
  return async (dispatch) => {
    let response = {};

    dispatch({type: 'DISPUTE_START_FETCHING_DATA'});
    try {
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: 'finance/partner-dispute/search/',
        data: search,
      });
      const {data, status} = response || {};
      if (status) {
        dispatch({type: 'DISPUTE_TABLE_DATA', payload: data});
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: 'DISPUTE_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'DISPUTE_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

// reducers
export default (state = INITIAL_STATE, action) => {
  console.log('ACTIONS', action);
  const {type, payload} = action || {};
  switch (type) {
    case 'DISPUTE_START_FETCHING_DATA':
      return {
        ...state,
        disputeLoading: true,
        disputeErrorMessage: '',

        reslovedisputeData: {},
      };

    case 'DISPUTE_TABLE_DATA':
      return {
        ...state,
        disputeLoading: false,
        disputeErrorMessage: '',
        disputeData: payload,
      };

    case 'DISPUTE_FAILED':
      return {
        ...state,
        disputeLoading: false,
        sendApproval: false,
        disputeErrorMessage: payload.error,
        final_approve: false,
        final_reject: false,
      };
    case 'RESOLVE_DISPUTE_TABLE_DATA':
      return {
        ...state,
        disputeLoading: false,
        disputeErrorMessage: '',
        reslovedisputeData: payload,
      };

    case 'DISPUTE_APPROVAL':
      return {
        ...state,
        sendApproval: true,
      };
    case 'SEND_FOR_APPROVAL':
      return {
        ...state,
        disputeLoading: false,
        disputeErrorMessage: '',
        sendApproval: false,
      };

    case 'EDIT_APPROVED_AMOUNT_REMARKS':
      return {
        ...state,
        disputeLoading: false,
      };

    case 'FINAL_APPROVE_FETCH':
      return {
        ...state,
        final_approve: true,
      };
    case 'FINAL_DONE_APPROVE_FETCH':
      return {
        ...state,
        final_approve: false,
      };

    case 'FINAL_REJECT_FETCH':
      return {
        ...state,
        final_reject: true,
      };

    case 'FINAL_DONE_REJECT_FETCH':
      return {
        ...state,
        final_reject: false,
      };
    default:
      return {
        ...state,
      };
  }
};
