const setInitalCityIdData = (state, payload = {}) => {
  const {city_ids} = payload;
  if (city_ids) {
    const oldCities = state;
    const newCities = city_ids;
    return [...oldCities, ...newCities];
  } else return state;
};

export default (state = [], action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default: {
      return setInitalCityIdData(state, payload);
    }
  }
};
