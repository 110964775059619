import {merge} from 'lodash';

const setInitalDriversData = (state, payload = {}) => {
  const {drivers} = payload;
  if (drivers) {
    const oldDrivers = {...state};
    const newDrivers = {...drivers};
    const updatedState = merge(oldDrivers, newDrivers);
    return {...updatedState};
  } else return state;
};

export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default:
      return setInitalDriversData(state, payload);
  }
};
