import {doRequest} from '../../helpers/network';
import {Annexure, Cost} from '../../helpers/Urls';
import {REQUEST_TYPE} from '../../constants';

export const getDhanukaAlerts = () => {
  return async (dispatch) => {
    let response = {};
    try {
      response = await doRequest({
        method: REQUEST_TYPE.GET,
        url: '/trip/get-dhanuka-alerts',
      });

      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: 'GET_DHANUKA_ALERTS_DATA_COMPLETED',
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: 'GET_DHANUKA_ALERTS_DATA_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'GET_DHANUKA_ALERTS_DATA_FAILED',
        payload: {error: err.message},
      });
    }

    return response;
  };
};

export const forSnooze = (sendData) => {
  return async (dispatch) => {
    let response = {};
    try {
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: '/trip/get-dhanuka-alerts',
        data: sendData,
      });

      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: 'GET_DHANUKA_ALERTS_DATA_COMPLETED',
          payload: {...data},
        });
      } else {
        const {message = 'Service Error'} = response || {};
        dispatch({
          type: 'GET_DHANUKA_ALERTS_DATA_FAILED',
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: 'GET_DHANUKA_ALERTS_DATA_FAILED',
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = {}, action = {}) => {
  return state;
};
