import {doRequest} from '../../helpers/network';
import {REQUEST_TYPE} from '../../constants';
import {LR} from '../../helpers/Urls';

export const CREATE_DIGITAL_LORRY_RECEIPT = 'CREATE_DIGITAL_LORRY_RECEIPT';
export const CREATE_DIGITAL_LORRY_RECEIPT_COMPLETED =
  'CREATE_DIGITAL_LORRY_RECEIPT_COMPLETED';
export const CREATE_DIGITAL_LORRY_RECEIPT_FAILED =
  'CREATE_DIGITAL_LORRY_RECEIPT_FAILED';

export const createDigitalLorryReceipt = (requestData) => {
  return async (dispatch) => {
    let response = {};
    try {
      dispatch({type: CREATE_DIGITAL_LORRY_RECEIPT});
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: LR.createDigitalLorryReceiptURL(),
        data: requestData,
      });
      const {data, status} = response || {};
      if (status === true) {
        dispatch({
          type: CREATE_DIGITAL_LORRY_RECEIPT_COMPLETED,
          payload: {...data},
        });
      } else {
        const {message = 'Error'} = response || {};
        dispatch({
          type: CREATE_DIGITAL_LORRY_RECEIPT_FAILED,
          payload: {error: message},
        });
      }
    } catch (err) {
      dispatch({
        type: CREATE_DIGITAL_LORRY_RECEIPT_FAILED,
        payload: {error: err.message},
      });
    }
    return response;
  };
};

export default (state = {}, action = {}) => {
  const {type, payload = {}} = action;
  switch (type) {
    default: {
      if (payload) {
        return {...state, ...payload};
      }
      return state;
    }
  }
};
